import axios from 'axios';
import convertKeys from '../utils/case-converter';
import { paramsSerializer } from '../utils/query-params';
const tokenEl = document && document.querySelector('meta[name="csrf-token"]');
const CSRFToken = tokenEl && tokenEl.getAttribute('content');
const api = axios.create({ transformRequest: [data => convertKeys(data, 'decamelize'), ...axios.defaults.transformRequest], transformResponse: [...axios.defaults.transformResponse, data => convertKeys(data, 'camelize')], headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'X-CSRF-Token': CSRFToken || false }, paramsSerializer });
export default api; /*
// Example to use the api object in the path ´app/javascript/api/users.ts´

import api from './index';

export default {
  index() {
    const path = '/api/internal/users';

    return api({
      method: 'get',
      url: path,
    });
  },
  create(data: Partial<User>) {
    const path = '/api/internal/users';

    return api({
      method: 'post',
      url: path,
      data: {
        user: data,
      },
    });
  },
  update(data: Partial<User>) {
    const path = `/api/internal/users/${data.id}`;

    return api({
      method: 'put',
      url: path,
      data: {
        user: data,
      },
    });
  },
};

*/
