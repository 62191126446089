import { VueQueryPlugin } from '@tanstack/vue-query';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { createPinia } from 'pinia';
import { Form } from 'vee-validate';
import { createApp, provide, ref } from 'vue';
import { createI18n } from 'vue-i18n';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import Draggable from 'vuedraggable';
import BaseBreadcrumb from './components/base-breadcrumb.vue';
import BaseButton from './components/base-button.vue';
import BaseCard from './components/base-card.vue';
import BaseCheckbox from './components/base-checkbox.vue';
import BaseChip from './components/base-chip.vue';
import BaseFileInput from './components/base-file-input.vue';
import BaseFormInfo from './components/base-form-info.vue';
import BaseInfoCard from './components/base-info-card.vue';
import BaseInput from './components/base-input.vue';
import BaseLabel from './components/base-label.vue';
import BaseModal from './components/base-modal.vue';
import BaseMultiselect from './components/base-multiselect.vue';
import BaseNotice from './components/base-notice.vue';
import BasePill from './components/base-pill.vue';
import BaseRadio from './components/base-radio.vue';
import BaseRadioGroup from './components/base-radio-group.vue';
import BaseSelect from './components/base-select.vue';
import BaseSidebar from './components/base-sidebar.vue';
import BaseStackedBarChart from './components/base-stacked-bar-chart.vue';
import BaseTable from './components/base-table.vue';
import BaseToggle from './components/base-toggle.vue';
import ClaimConfirmation from './components/claim-confirmation.vue';
import ClaimForm from './components/claim-form.vue';
import ClaimList from './components/claim-list.vue';
import ClaimPointForm from './components/claim-point-form.vue';
import ClaimPointList from './components/claim-point-list.vue';
import ClaimState from './components/claim-state.vue';
import ClaimTemplateFormView from './components/claim-template-form-view.vue';
import ClaimTemplateSelectView from './components/claim-template-select-view.vue';
import ClaimTemplateView from './components/claim-template-view.vue';
import ClaimTemplatesList from './components/claim-templates-list.vue';
import CommentDetails from './components/comment-details.vue';
import CommentForm from './components/comment-form.vue';
import CompaniesList from './components/companies-list.vue';
import CompanyConfigurationForm from './components/company-configuration-form.vue';
import CompanyDetails from './components/company-details.vue';
import CompanyFormSteps from './components/company-form-steps.vue';
import CompanyForm from './components/company-form.vue';
import HomeView from './components/home-view.vue';
import PasswordEdit from './components/password-edit.vue';
import PasswordReset from './components/password-reset.vue';
import RecommendationForm from './components/recommendation-form.vue';
import RecommendationList from './components/recommendation-list.vue';
import StatisticsView from './components/statistics-view.vue';
import TheAdminSidebar from './components/the-admin-sidebar.vue';
import TheControlPanelSidebar from './components/the-control-panel-sidebar.vue';
import TheNavbar from './components/the-navbar.vue';
import TheQrclaimFooter from './components/the-qrclaim-footer.vue';
import UserFormView from './components/user-form-view.vue';
import UserLoginForm from './components/user-login-form.vue';
import { useSiteVariables } from './composables/use-site-variables';
import './config/yup';
import './css/application.css';
import Locales from './locales/locales';

const pinia = createPinia();

const i18n = createI18n({
  legacy: false,
  locale: process.env.APPLICATION_TYPE === 'karin' ? 'es-KARIN' : 'es',
  fallbackLocale: 'es',
  messages: Locales.messages,
  warnHtmlMessage: process.env.APPLICATION_TYPE !== 'karin',
});

const globalComponents = {
  BaseBreadcrumb,
  BaseButton,
  BaseCard,
  BaseCheckbox,
  BaseChip,
  BaseFormInfo,
  BaseFileInput,
  BaseInfoCard,
  BaseInput,
  BaseLabel,
  BaseModal,
  BaseMultiselect,
  BaseNotice,
  BasePill,
  BaseRadio,
  BaseRadioGroup,
  BaseSelect,
  BaseSidebar,
  BaseStackedBarChart,
  BaseTable,
  BaseToggle,
  'VForm': Form,
  Draggable,
  TheQrclaimFooter,
};

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      TheAdminSidebar,
      ClaimConfirmation,
      ClaimForm,
      ClaimPointForm,
      ClaimPointList,
      ClaimList,
      ClaimState,
      ClaimTemplateFormView,
      ClaimTemplatesList,
      ClaimTemplateSelectView,
      ClaimTemplateView,
      CommentDetails,
      CommentForm,
      CompanyConfigurationForm,
      CompanyDetails,
      CompanyForm,
      CompaniesList,
      HomeView,
      TheControlPanelSidebar,
      RecommendationForm,
      RecommendationList,
      TheNavbar,
      UserFormView,
      UserLoginForm,
      CompanyFormSteps,
      StatisticsView,
      PasswordEdit,
      PasswordReset,
    },
    setup() {
      const siteVariables = useSiteVariables();
      const currentUser = ref(siteVariables.currentUser);

      provide('currentUser', currentUser);
      provide('clearCurrentUser', () => {
        currentUser.value = undefined;
      });
      provide('setCurrentUser', (user) => {
        currentUser.value = user;
      });
    },
  });

  Object.entries(globalComponents).forEach(([name, component]) => {
    app.component(name, component);
  });

  app.use(FloatingVue,
    { themes: {
      'qrclaim-tooltip': {
        $extend: 'tooltip',
      },
    },
    });
  app.use(i18n);
  app.use(VueQueryPlugin);
  app.use(VueSvgInlinePlugin);
  app.use(pinia);

  app.mount('#vue-app');

  return app;
});
